<template>
  <div>
    <v-row align="center">
      <v-col>
        <h2>Overview</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-title class="accent white--text">Quick Brief</v-card-title>
          <v-simple-table>
            <tbody>
              <tr>
                <th>Status</th>
                <td class="text-right">
                  <v-chip
                    label
                    small
                    :color="tour.status === 'tour' ? 'green' : 'orange'"
                    text-color="white"
                    >{{
                      tour.status === "tour" ? "Confirmed" : "Proposal"
                    }}</v-chip
                  >
                </td>
              </tr>
              <tr>
                <th>Start Date</th>
                <td class="text-right">
                  <span
                    v-if="
                      proposedItinerary && proposedItinerary.finance.start_date
                    "
                    v-html="proposedItinerary.finance.start_date"
                  ></span>
                  <span v-else class="text--disabled">N/A</span>
                </td>
              </tr>
              <tr>
                <th>Lead Customer</th>
                <td class="text-right">
                  <router-link
                    :to="{
                      name: 'module-drum-customers-profile',
                      params: { customerId: tour.lead_customer.id },
                    }"
                    >{{ tour.lead_customer.full_name }}</router-link
                  >
                </td>
              </tr>
              <tr>
                <th>Number of Golfers</th>
                <td class="text-right">{{ tour.total_golfers }}</td>
              </tr>
              <tr>
                <th>Number of Non-Golfers</th>
                <td class="text-right">{{ tour.total_non_golfers }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-title class="accent white--text"
            >Finance Overview</v-card-title
          >
          <v-simple-table>
            <tbody>
              <tr>
                <th>Customer's Currency</th>
                <td class="text-right">
                  <span
                    v-if="tour.currency_symbol"
                    v-html="tour.currency_symbol"
                  ></span>
                  <span v-else class="text--disabled">N/A</span>
                </td>
              </tr>
              <tr>
                <th>Currency's Exchange Rate</th>
                <td class="text-right">
                  <span
                    v-if="tour.exchange_rate"
                    v-html="tour.exchange_rate"
                  ></span>
                  <span v-else class="text--disabled">N/A</span>
                </td>
              </tr>
              <tr>
                <th>Total Proposed Price</th>
                <td class="text-right">
                  <span
                    v-if="proposedItinerary"
                    v-html="proposedItinerary.finance.total_price"
                  ></span>
                  <span v-else class="text--disabled">N/A</span>
                </td>
              </tr>
              <tr>
                <th>Total Paid By Customer</th>
                <td class="text-right">
                  <span
                    v-if="proposedItinerary"
                    v-html="proposedItinerary.finance.total_payments"
                  ></span>
                  <span v-else class="text--disabled">N/A</span>
                </td>
              </tr>
              <tr>
                <th>Total Amount Outstanding By Customer</th>
                <td class="text-right">
                  <span
                    v-if="proposedItinerary"
                    v-html="proposedItinerary.finance.outstanding"
                  ></span>
                  <span v-else class="text--disabled">N/A</span>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  computed: {
    tour() {
      return this.$store.state.drum.tours["tour"];
    },

    proposedItinerary() {
      const itineraries = this.tour.itineraries.filter((i) => {
        return i.status === "Confirmed" || i.status === "Accepted";
      });

      return itineraries[0];
    },
  },
};
</script>